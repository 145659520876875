<template>
  <div id="maintenance">
    <div class="main-page">
      <div>
        <img class="logo" src="/liberrex_white.png" width="300" />
      </div>
      <div class="text">
        <p>{{$t("Maintenance.Down")}}</p>
        <p>{{$t("Maintenance.Back")}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Maintenance",
  data: () => ({
    teller: {},
    user: {}
  }),
  mounted() {
    const self = this;
    console.log('mounteeeeed')
    //Get user from localstorage
    self.user = JSON.parse(localStorage.getItem("user")).user;
    self.teller = JSON.parse(localStorage.getItem("user"));

      // change maintenance
      self.$pusher.channel("private-teller-" + self.user.id).listen(
        ".maintenance-change",
        function(data) {
          console.log("___teller: ", data);
          self.teller.user.maintenance = data.status;
          localStorage.setItem("user", JSON.stringify( self.teller));
          console.log('xxxxxxx222222222 : ', data.status == 1)
              if(data.status == 0) {
                console.log('inside maintenance')
                self.$router.push("/waitinglist");
              }
        }.bind(this)
      );

      if (this.user && this.user.maintenance == 0) {
      this.$router.push("/");
    }
  }
};
</script>

<style lang="scss">
#maintenance {
  .main-page {
    width: 100vw;
    height: 100vh;
    background-color: #3d3d3d;
  }
  .bottom {
    width: 100vw;
    height: 15vh;
    background-color: #eee;
    position: absolute;
    bottom: 0;
  }

  .logo {
    margin: 2rem;
  }

  .text {
    position: fixed;
    left: 0;
    top: 50%;
    transform: translate(10%, -60%);
    color: #eee;
    font-size: 4rem;
    font-family: "BaiJamjuree";
  }

  @media screen and (min-width: 1440px) {
    .text {
    position: fixed;
    left: 0;
    top: 50%;
    transform: translate(15%, -100%);
    color: #eee;
    font-size: 4rem;
    font-family: "BaiJamjuree";
  }
}

@media screen and (min-width: 2560px) {
  .text {
    position: fixed;
    left: 0;
    top: 50%;
    transform: translate(15%, -160%);
    color: #eee;
    font-size: 6rem;
    font-family: "BaiJamjuree";
  }
}
}
</style>
